.category_product_sec1{ padding: 30px 0;}
.category_product_sec1 span{    margin-top: 30px; display: flex; justify-content: center;}
.category_product_colm {   width: 100%;  display: inline-block;}
.category_product_sec1 h1{ font-family: Cormorant Garamond;font-size: 28px; line-height: 1.3;letter-spacing: 0.15px; padding-bottom: 10px; font-weight: 500;}
/* .category_product_sec1 h2{ font-family: Cormorant Garamond;font-size: 28px; line-height: 1.3;letter-spacing: 0.15px; padding-bottom: 10px; font-weight: 500;} */
.category_product_colm h2{font-size: 23px; margin-bottom: 0px;  }
.category_product_colm p:last-child{ margin-bottom: 0;}
.category_product_colm strong{ font-weight:600;    color: #313131;}
.category_product_colm br{ display: none;}
.category_sort { position: relative; display: table; width: 100%;}		
.category_sort h1{font-size: 22px;letter-spacing: 0.15px; float: left; margin-bottom: 10px;font-weight:600;}		
.category_sort .select_size { float: right; position: relative;margin-top: 4px;}	
.category_sort .select_size a { float: right; font-weight: 600; border: 0; font-size: 12px; color: #C4601D; appearance: none;-webkit-appearance: none; padding-right: 25px;text-transform: uppercase;letter-spacing: 1.25px; position: relative;}
.category_sort .select_size a:before   {pointer-events: none; content: ""; background-image: url(/images/icon-history.inline.svg); width:23px; height:23px;  position: absolute;right: 0; top: -2px; background-size: cover; }
.category_sort .select_size a.active span:before{    content: "."; color: green; margin-right: 6px;   font-size: 40px;
line-height: 0;  display: inline-block; position: relative; top: -2px;}
.category_sort .select_sizea span{ position: relative;}
.category_sort .select_size_sort:before{pointer-events: none; content: ""; background-image: url(/images/sort-amount-down.inline.svg); width:17px; height:15px;  position: absolute;right: 0; top:3px; background-size: cover;}
.skeleton p{margin-bottom: 4px;}
.select_size_sort{ position: relative;    float: left;  margin-right: 20px;    padding-right:5px;}
.skeleton li{padding: 5px;}
.skeleton .category_product_colm{padding:20px}
.category_product_colm span{text-align: left;padding-top: 5px; display: block;}
.category_product_colm span a{letter-spacing: 1.25px;text-transform: uppercase;
     font-size: 12px; font-weight: 600; color: #C4601D !important;}
.category_product_sec1 span img{ display: block;    width: 129px;}
.filter_popup_sec h5.modal-title { font-size: 14px;line-height: 1.4;}	
.filter_popup_sec .modal_header button.btn_close {font-size: 14px;}	
.filter_popup_sec .modal_header {border-bottom: 1px dotted #946134;}
.filter_popup_sec .modal_content { padding:0 12px}
.filter_popup_sec .modal_header {display: flex; flex-shrink: 0;align-items: center;justify-content: space-between;padding: 1rem 0;  }
.filter_popup_sec .modal_body {position: relative; flex: 1 1 auto; padding: 1rem 0;}
.category_filter_colm{ width: 100%; border-bottom: 1px solid #f0ddd1; margin-bottom: 20px; padding-bottom:15px; display: table;}
.category_filter_colm h3{font-family: Open Sans;font-size: 14px; font-weight: 600; padding-bottom: 12px;}
.category_filter_colm ul li {  float: left;margin-right:15px;  margin-bottom: 8px;}
.category_filter_colm ul li a {  display: block; color: #828282;  font-size: 14px; border: 1px solid #828282; border-radius: 5px;  padding: 8px 15px;}
.category_filter_colm2 li span {    display: block; margin: 0 auto 6px;  border: 1px solid #313131; border-radius: 50%;  width: 66px; height: 66px;padding-top: 15px;}		
.category_filter_colm2 li span img{    -webkit-filter: grayscale(100%);filter: grayscale(100%);}
.category_filter_colm2 li a { font-size: 14px; text-align: center;display: block;  width: 100%;}	
.category_filter_colm2 li a.active{color: #C4601D; }
.category_filter_colm2 li a.active span img, .category_filter_colm2 li a:hover span img{-webkit-filter: grayscale(0);filter: grayscale(0);}
.category_filter_colm2 li a.active span, .category_filter_colm2 li a:hover span{ border-color: #C4601D;}
.category_filter_colm ul li .active, .category_filter_colm ul li a:hover{ color:#C4601D; border-color: #C4601D; }
    .category_product_colm img{ width: 100%; display: block;}
    .category_product_sec1{ padding-top: 0;}
  .ayurveda_sec1_row {
        box-shadow: 0px 3px 6px rgb(49 49 49 / 16%); margin-top: -20px;  display: table; width: 100%;
     background: #fff; z-index: 1;  position: relative;
     min-height: 11rem;
        border-radius: 5px; padding: 15px;}
    .ayurveda_sec1_row_noimg{box-shadow: 0px 3px 6px rgb(49 49 49 / 16%);  display: table; width: 100%;
        background: #fff; z-index: 1;  position: relative;
           border-radius: 5px; padding: 15px;}
.wrap_categories{ width: 100%; position: relative;
     background-color: rgb(194 96 30 / 5%); padding: 25px;border-radius: 5px; height: 100%;}
    .wrap_categories h3{font-size: 28px; font-weight: 400; padding-bottom: 15px;}
    .wrap_categories li{ position: relative; display: inline-block;     width: 50%;
        padding-right: 15px;  padding-bottom: 18px;    padding-left: 32px;}
    .wrap_categories li a{ font-size: 13px;letter-spacing: 0.5px;    color: #313131;}
    .wrap_categories li:before{ content: ""; left:0;     background-size: cover;
        background-image: url(/images/motif2.inline.svg); position: absolute; width: 26px; height: 26px;}
.wrap_categories_main{  width: 100%;}
.wrap_categories_main p{ font-size: 13px; line-height: 28px;letter-spacing: 0.25px; }
.wrap_categories:before{ background-image: url(/images/motif1.inline.svg); position: absolute; width: 130px;
height: 39px; background-size: cover; content: "";     top: -39px;left: 50%;
transform: translate(-50%, 0);}
.wrap_categories_row{ padding-top: 60px;}
.wrap_categories_main p{ margin-bottom: 10px;}
.categories_colm{ margin-top: 69px; }
.shop_by_menu { width: 100%; display: table;}	
.shop_by_menu ul li{ float: left;    padding-bottom: 10px;color: #C4601D;}
.shop_by_menu ul li a{ cursor:pointer;display:  block; padding:10px 15px;font-size: 14px;color: #C4601D;letter-spacing: 0.25px; font-weight: 600;border-radius: 5px;border: 1px solid #C4601D;    margin-right: 15px;}
.shop_by_menu ul li a img{ margin-left: 5px; width: 12px;}
.select_size select{ cursor: pointer;  float: left;  width: 80px;  font-weight: 600; border: 0; font-size: 13px;
 color: #C4601D;   -moz-appearance: none;appearance: none;  -webkit-appearance: none;
 padding-right: 10px;  text-transform: uppercase;  letter-spacing: 1.25px;}

 .anniversary_sec12{ width: 100%; text-align: center;     padding: 20px 12px 35px; display: inline-block;
    font-size: 18px;letter-spacing: 1.25px;line-height: 1;    font-weight: 600;     background: #f9f8f8; color: #c4601d;z-index: 95; position: relative;}
  .anniversary_sec12 h2{font-size: 26px;text-align: center; font-weight: 500;    line-height: 1;color: #313131; letter-spacing: 0.25px; }
    .anniversary_sec12 h2 a{    font-size: 12px;font-family: 'Open Sans'; box-shadow: 0px 0px 16px rgb(82 53 40 / 16%);
      background: #c4601d;color: #fff;text-transform: uppercase;display: inline-block;
   padding: 8px 12px;border-radius:5px;    letter-spacing: 1.25px; font-weight: 600;}
   .anniversary_sec12 h2 sup{    top: -0.2em;    font-size: 26px;}
    .anniversary_sec12 h2 abbr{ display: block;}
  .anniversary_sec12 span{ position: relative; background: #c4601d;color: #fff;  padding: 10px 12px;  display: inline-block;border-radius: 10px;margin: 0 10px;}    
  .anniversary_sec12 span:before{ content: "Hours"; position: absolute;font-size: 13px; left: 50%; bottom: -18px; text-align: center; color: #c4601d; transform: translate(-50%, 0); letter-spacing: normal;}
  .anniversary_sec12 span.a_sec12_2:before{content: "Minutes";}
  .anniversary_sec12 span.a_sec12_3:before{content: "Seconds";}
  .anniversary_sec12 span.a_sec12_4:before{content: "Days";}
  .anniversary_sec12 > div{display: flex;justify-content: center;align-items: center;}
.anniversary_sec12 p{ margin: 0; color: #c4601d;}
.sticky_anniversary{ top:100px; position: fixed;z-index: 995; padding: 15px 12px 15px;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}
.anniversary_button_sec{ margin-left: 10px;  }
.anniversary_button_sec button img{    vertical-align: sub; margin-right: 3px;}
.anniversary_button_sec button{    letter-spacing: 1.25px; color: #ffffff;font-size: 11px;text-transform: uppercase;
background: #C4601D;border-radius: 5px; display: table;padding: 7px 10px; text-align: center;font-weight: 600;}
.category_product_sec1 .category_product_sec1_skeleton span{ margin-top: 0;}
    @media (min-width: 992px){
        .plpBanner{
            aspect-ratio: 7 / 2;
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        .wrap_categories_blog{ padding-top: 30px;}
        .category_product_sec1 span img{    width: 184px; margin: 0 auto;}
        .category_product_sec1 h1{    font-size: 40px;padding-bottom:10px;font-weight: 400;padding-top: 5px;    padding-top: 5px;}
        .category_sort h1{font-size: 33px;  padding-bottom: 20px; margin: 0;  
            text-transform: capitalize;font-weight: 400;    line-height: 1;}
        .category_sort .select_size a{ font-size: 13px; cursor: pointer;}
        .category_sort .select_size {    margin-top: 0px; margin-bottom: 20px;}
        .category_sort{ padding-top: 30px;}
        .category_product_sec1{ padding-top: 0;}
        .category_product_sec1 picture img{ width: 100%;}
    .ayurveda_sec1_row {    margin-top: -40px; padding: 25px 25px 25px;}
    .ayurveda_sec1_row div{ padding-top: 0;}
    .category_product_sec1 span img{ width: 180px; }
    .wrap_categories_row .category_product_colm h2{    font-size: 28px; }
    .ayurveda_sec1_row_noimg{padding:25px;}
    .ayurveda_sec1_row_noimg div{ padding-top: 0;}
    .category_product_sec12{ padding-bottom: 0;}
    }
    .btn_close{    position: absolute;right: 20px;top: 20px;}
    .dashboard_orders_popup .modal_dialog {width: 100%;margin: 0;}
.modal_input input{ border: 0;width: 100%;    display: inline-block;border-bottom: 1px solid #f0ddd1; font-size: 14px;
 padding: 4px 0;}
.modal_input label{font-size: 12px;color: #c4601d;padding-bottom: 5px;width: 100%; position: relative; display: block;}
.modal_input { display: inline-block; padding-bottom: 25px; width: 100%;}
.modal_input_btn{    display: inline-block;width: 100%;margin-top: 30px;}
.modal_input_btn button{background-color: #c4601d;
width: 100%;color: #fff;display: block;padding: 13px 0;border-radius: 5px;-webkit-box-shadow: 0 0 16px rgb(82 53 40 / 16%);box-shadow: 0 0 16px rgb(82 53 40 / 16%);text-transform: uppercase;letter-spacing: 1.25px;font-size: 12px;text-align: center;font-weight: 600;border: 0;}.btn-close{    position: absolute;right: 20px;top: 20px;}
.dashboard_orders_popup .modal_dialog { margin: 0;}
.dashboard_orders_popup .modal_content { border: 0; border-radius: 0;    border-top-left-radius: 10px;    border-top-right-radius: 10px;}
.modal.fade.dashboard_orders_popup .modal_dialog {transform: translate(0, 50px); position: absolute; bottom: 0;}
.modal.fade.dashboard_orders_popup.show .modal_dialog { transform: none;}
.dashboard_orders_popup h5.modal_title {font-size: 28px;font-family: Cormorant Garamond; padding-top: 30px; line-height: 1.3;font-weight: 500;}
.dashboard_orders_popup .modal_header{ border: 0; padding:20px 30px 0;    align-items: inherit;}
.dashboard_orders_popup .modal_body{ padding:20px 30px;}
.dashboard_orders_popup{ width: 435px; max-width: 100%;position: relative; height: 100%;}
.dashboard_orders_popup input:-webkit-autofill,  .dashboard_orders_popup select:-webkit-autofill { -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;}
  .invalid_feedback{ padding-top:10px; font-size: 13px; letter-spacing: 0.25px;}
.category_product_navratri{ display:block; position: relative;padding-bottom:50px; padding-top: 50px; }
    .category_product_navratri_main span{ width:250px;  display: block;    transition: all 0.32s ease-out;    margin: 0 5px;}
    /* .category_product_navratri_main span:hover{transform: scale(1.3); z-index: 4; position: relative;} */
    .category_product_navratri_main .category_show{transform: scale(1.5) !important; display: block; z-index: 3; position: relative;    transition: all 0.32s ease-out; }
    .category_show_span{ display: flex; flex-wrap: nowrap;    justify-content: center;}
    .category_product_navratri_main span img{ width: 100%;}

    @media (min-width: 1920px){
        .category_product_navratri_main span:hover{transform: scale(1.2);}
        .category_product_navratri_main .category_show{transform: scale(1.3) !important; }
    }
    
    @media (max-width: 767px){
        .plpBanner{
            aspect-ratio: 3 / 2;
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        .category_product_navratri_main span{ width:100%; }
        .category_product_navratri_main ul {
            padding: 30px 0
        }
        .category_show_span{     justify-content: inherit;}
        .category_product_navratri_main ul li {
            -webkit-transform-style: flat;
            -webkit-transform-style: initial;
            transform-style: flat
        }
        .category_product_navratri_main span:hover{transform: scale(1.2); z-index: 4; position: relative;}
        .category_product_navratri_main .category_show{transform: scale(1.2) !important; display: block; z-index: 3; position: relative;    transition: all 0.32s ease-out; left: 0; right: 0px;}
        .category_product_navratri{ padding-bottom: 30px; padding-top: 0;}
        .anniversary_button_sec{     margin-left: auto;   display: table;
            padding-top: 25px;
            margin: 0 auto;
            padding-bottom: 4px;}
        .category_product_sec12{ padding-bottom: 0;}
        .anniversary_sec12 p{ font-size: 13px; }
        .anniversary_sec12{    padding: 12px 12px 27px; font-size: 13px; line-height: 1.4;letter-spacing: 0.25px;top: 2px; z-index: 8;}
    .anniversary_sec12 span:before{    font-size: 12px;    bottom: -16px;}
    .anniversary_sec12 span{    padding: 8px 10px;}
    .anniversary_sec12 > div{display:inline-block;}
    .anniversary_sec12 h2{    font-size: 19px;    line-height: 1.3; padding-bottom:6px;    font-weight: 600;}
        .categories_colm{ margin-top:59px;}
        .wrap_categories h3{    font-size: 22px; font-weight: 600;}
        .wrap_categories li{ width: 100%;padding-bottom: 15px;}
        .wrap_categories{ padding: 20px 15px;    margin-bottom: 68px; height: initial;}
        .wrap_categories_row{ padding-top: 30px;}
        .wrap_categories_main div:last-child .wrap_categories{margin-bottom: 0;}
        .select_size_sort{ margin-right: 12px;    padding-right: 1px;}
        .select_size select{ font-size: 12px; }
        .shop_by_menu ul li a{    padding: 6px 15px;margin-right: 10px; font-size: 13px;}
        .shop_by_menu ul li a img{     top: 2px; position: relative;}
        .category_sort h1{ float: none; margin: 0;}
        .category_sort .select_size{    margin-top: 6px;  display: inline-block;width: 100%;    float: none; margin-bottom: 2px;}
        .category_product_colm h2{ font-size: 20px;line-height: 1.4;}
        .cutoffprice{ font-size: 18px;}
        .category_product_sec1 h1{padding-bottom:6px;}
        .sticky_anniversary{ top:82px;    padding: 8px 12px 8px;    z-index: 8;}
        .sticky_anniversary p{ font-size: 12px;}
        .sticky_anniversary + .anniversary_sec12_mergin{    padding-top:82px;}

        .dashboard_orders_popup_notify{ width: 100%;}
        .dashboard_orders_popup_notify .dashboard_orders_popup h5.modal_title{ padding-top: 0;    font-size: 22px;}
        .dashboard_orders_popup_notify .dashboard_orders_popup .modal_header{padding: 12px 15px 12px;    border-bottom: 1px solid #f6e4db;}
        .dashboard_orders_popup_notify .modal_input_btn button{top: 16px;}
        .dashboard_orders_popup_notify .dashboard_orders_popup .modal_body{ padding:20px 15px 0;}
        .dashboard_orders_popup_notify .modal_input{    padding-bottom: 15px;}
        .dashboard_orders_popup_notify .modal_input_btn {    margin-top: 10px;}
        .dashboard_orders_popup{ width: 100%;    position: absolute; background-color: #fff; bottom: 0; border-top-left-radius: 10px;
            border-top-right-radius: 10px;max-width: 100%;height: initial;}
    }
    .category_product_sec1 video{ display: block;}














    @-webkit-keyframes fadeInDown { from { opacity:0; -webkit-transform: translatey(-10px);-moz-transform: translatey(-10px);-o-transform: translatey(-10px); transform: translatey(-10px);}   to {opacity:1; -webkit-transform: translatey(0); -moz-transform: translatey(0); -o-transform: translatey(0);transform: translatey(0); } }
    @-moz-keyframes fadeInDown { from {  opacity:0; -webkit-transform: translatey(-10px); -moz-transform: translatey(-10px);-o-transform: translatey(-10px);transform: translatey(-10px);} to {  opacity:1; -webkit-transform: translatey(0);  -moz-transform: translatey(0);  -o-transform: translatey(0);transform: translatey(0);} }
    @keyframes fadeInDown { from { opacity:0; -webkit-transform: translatey(-10px); -moz-transform: translatey(-10px);-o-transform: translatey(-10px); transform: translatey(-10px); } to {  opacity:1; -webkit-transform: translatey(0); -moz-transform: translatey(0);-o-transform: translatey(0); transform: translatey(0);}}










