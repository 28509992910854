.btnWishlist {
    position: absolute;
    right: 8px;
    top: 8px; z-index: 1;
  }
  .btnWishlistclick { background-image: url(/images/wishlist.inline.svg);
    width: 28px !important;
    height: 28px !important;z-index: 1;
    display: block;}
  .btnWishlistclick path{ display: none;}
  .btnwishlistonclick path{ display: none;}
  .btnwishlistonclick{background-image: url(/images/wishlist-like.inline.svg);  width: 28px !important;
    height: 28px !important;z-index: 1;
    display: block;}



    @media (min-width: 992px){
      .btnWishlist{ top: auto; bottom: 66px; opacity: 0;visibility: hidden; right: 0; cursor: pointer;}
      

    } 